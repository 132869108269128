export const theme = {
  breakpoints: {
    desktop: '990px',
  },
  colors: {
    white: '#ffffff',
    brand: {
      DEFAULT: '#03A8D9',
      dark: '#088AB2',
      darker: '#1F505C',
    },
    action: {
      DEFAULT: '#03A0CE',
      secondary: '#04B7EC',
      tertiary: '#67D7F9',
      quaternary: '#F5FDFF',
    },
    success: {
      DEFAULT: '#059467',
      border: '#36D399',
      bg: '#EDFDF5',
    },
    warning: {
      DEFAULT: '#D97706',
      border: '#FBBD23',
      bg: '#FFFBEB',
    },
    shadow: {
      focused: '#06b6d4',
      error: '#ef4444',
    },
    error: {
      DEFAULT: '#BA1C1C',
      border: '#EF4343',
      borderlight: '#FCA6A6',
      bg: '#FEF1F1',
      placeholder: '#F87272',
    },
    info: {
      DEFAULT: '#088EAF',
      border: '#20D3EE',
      bg: '#EBFEFF',
    },
    heading: '#111827',
    base: '#1F2937',
    detail: '#6B7280',
    backgroundLight: '#FAFAFA',
    backgroundMid: '#F2F2F2',
    backgroundDark: '#E3E7E8;',
    accentMid: '#C6D0D2',
    accentDark: '#8da0a5',
    mobileMenuIcon: '#C4C4C4',
    borderContent: '#E3EBED',
    borderInput: '#D1D9DB',
    sectionHeading: '#205360',
    life: {
      DEFAULT: '#7C3AED',
      gradient:
        'linear-gradient(91.22deg, rgba(124, 58, 237, 0.5) 0%, rgba(124, 58, 237, 0.15) 49.22%, rgba(124, 58, 237, 0.05) 100%)',
      shadeLight: '#7C3AED08',
      shadeDark: '#7C3AED0D',
    },
    health: {
      DEFAULT: '#0D9488',
      gradient:
        'linear-gradient(91.22deg, rgba(13, 148, 136, 0.5) 0%, rgba(13, 148, 136, 0.15) 49.22%, rgba(13, 148, 136, 0.05) 100%)',
      shadeLight: '#0D94880A',
      shadeDark: '#0D948812',
    },
    medicare: {
      DEFAULT: '#ED970C',
      text: '#DC7A18',
      gradient:
        'linear-gradient(91.22deg, rgba(237, 151, 12, 0.5) 0%, rgba(237, 151, 12, 0.15) 49.22%, rgba(237, 151, 12, 0.05) 100%)',
      shadeLight: '#ED970C0A',
      shadeDark: '#ED970C12',
    },
    auto: {
      DEFAULT: '#DB2777',
      gradient:
        'linear-gradient(91.22deg, rgba(219, 39, 119, 0.5) 0%, rgba(219, 39, 119, 0.15) 49.22%, rgba(219, 39, 119, 0.05) 100%)',
      shadeLight: '#DB27770A',
      shadeDark: '#DB297912',
    },
    home: {
      DEFAULT: '#F97415',
      text: '#F2690D',
      gradient:
        'linear-gradient(91.22deg, rgba(249, 116, 21, 0.5) 0%, rgba(249, 116, 21, 0.15) 49.22%, rgba(249, 116, 21, 0.05) 100%)',
      shadeLight: '#F974150A',
      shadeDark: '#F9741512',
    },
    pet: {
      DEFAULT: '#0284C7',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
    settings: {
      DEFAULT: '#205360',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
    careers: {
      DEFAULT: '#66DBFF',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
    agents: {
      DEFAULT: '#66DBFF',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
    identity: {
      DEFAULT: '#14B8AA',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
    rx: {
      DEFAULT: '#25A850',
      gradient:
        'linear-gradient(91.22deg, rgba(2, 132, 199, 0.5) 0%, rgba(2, 132, 199, 0.15) 49.22%, rgba(2, 132, 199, 0.05) 100%)',
      shadeLight: '#0284C70A',
      shadeDark: '#0284C712',
    },
  },
}
