export default {
  openGraph: {
    type: 'website',
    locale: 'en_US',
    site_name: 'PolicyScout',
  },
  twitter: {
    handle: '@PolicyScout',
    site: '@PolicyScout',
    cardType: 'summary_large_image',
  },
}
