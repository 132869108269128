import Head from 'next/head'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { theme } from 'src/styles/theme'
import { DefaultSeo } from 'next-seo'
import { GTM_ID, pageview } from '../utils/gtm'
import SEO from '../next-seo.config'
import { Amplify, Auth } from 'aws-amplify'

import 'normalize.css'
import { useEffect } from 'react'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Libre Franklin', ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    color: #1F2937;

    *, *::before, *::after {
      box-sizing: border-box;
    }

    button {
      cursor: pointer;
    }

    button, input, textarea {
      font: inherit;
    }

    #root, #__next {
      isolation: isolate;
    }

    .hide-mobile {
      @media (max-width: ${theme.breakpoints.desktop}) {
        display: none;
      }
    }

    .hide-desktop {
      display: none;
      @media (max-width: ${theme.breakpoints.desktop}) {
        display: initial;
      }
    }
  }

  .ps-no-scroll {
    overflow: hidden;
  }
`

const REGION = process.env.NEXT_PUBLIC_AWS_COGNITO_REGION
const POOL_ID = process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID
// const USER_POOL_ID = process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID
const CLIENT_ID = process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID

Amplify.configure(
  {
    aws_cognito_region: REGION,
    aws_cognito_identity_pool_id: POOL_ID,
    aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: CLIENT_ID,
    // ssr: true,
  },
  {
    Auth: {
      identityPoolId: POOL_ID,
      region: REGION,
    },
  },
)

Auth.configure({
  identityPoolId: POOL_ID,
  region: REGION,
})

function App({ Component, pageProps, err }) {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
  }, [router.events])

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />

      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/favicon.png" />
        <meta
          name="ahrefs-site-verification"
          content="c93bd58d8e75426dc78ebd4fd72b2a63497cc75effc5e12152f42e6b012ff896"
        ></meta>
      </Head>

      <DefaultSeo {...SEO} />

      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </>
  )
}

export default App
